.comment_input{
    width:  100% ;
    background-color: #DCDCDC;
    border-radius: 10px;
    padding: 6px;
    font-size: 17px;
    border: none;
    outline: none;
    display: flex;
}
.postPic{
    width:100%;
    height:300px;
}
.Post
{
    margin-top: 0.7em;
    /* margin: 1em; */
    /* margin-left: 2em; */
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: white;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
}

.dark-mode-inner{
    background-color: #1a1919;
    transition: background-color 0.25s ease-in-out;
  }
  .light-mode-inner{
    background-color: white;
    transition: background-color 0.25s ease-in-out;
  }

.Post>img{
    width: 100%;
    max-height: 20rem;
    object-fit: cover;
    border-radius: 0.5rem;
}

.postReact{
   margin-top: 0.5em;
   margin-bottom: 0.4em;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    font-size: larger;
}
.leftSide{
    padding-right: 0;
}

.rightSide{
    min-height: 25.8em;
    background-color: white;
    padding: 28px;
    margin-top: 0.69em;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
}