.logout-button {
  width: 100%;
  height: 2rem;
  margin-top: 2rem;
  align-self: flex-start;
}

.FollowersCard {
  padding: 20px;
  width: 100%;
  border-radius: 0.7rem;
  /* gap: 1rem; */
  display: flex;
  flex-direction: column;
  font-size: 13px;
  max-height: 10rem;
}

.dark-mode-inner {
  background-color: #1a1919;
  transition: background-color 0.25s ease-in-out;
}
.light-mode-inner {
  background-color: white;
  transition: background-color 0.25s ease-in-out;
}

.follower {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.follower > div {
  display: flex;
  gap: 10px;
}

.followerImage {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.name > span:nth-of-type(1) {
  font-weight: bold;
}

.fc-button {
  height: 2rem;
  width: 35%;
  padding-left: 5px;
  padding-right: 5px;
}

.UnfollowButton {
  color: var(--orange);
  border: 2px solid var(--orange);
  cursor: pointer;
  background: transparent;
}

.FollowersCard > span {
  font-weight: bold;
  color: orange;
  align-self: center;
  cursor: pointer;
}
