.videoContainer {
	border-radius: 0.7em;
	width: 400px;
}

.row-container{
	display: flex;
	align-items: center;
	justify-content: center;
}

.video-div{
	display: flex;
	justify-content: center;
	align-items: center;
}

.call-btn{
	color: white;
	padding: 10px;
	border-radius: 50%;
	background-color: rgb(12, 239, 23);
}

.call-btn:hover{
	background-color: rgb(115, 234, 121);
}

.end-btn{
	margin: 10px;
	color: white;
	padding: 10px;
	border-radius: 50%;
	background-color: rgb(235, 10, 10);
	border: none;
}

.end-btn:hover{
	background-color: rgb(246, 104, 65);
}


.video-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: center;
	align-content: center;
	margin-top: 10rem;
	margin-left: 10rem;
}

.selected {
	background-color: #ddd;
}

.mute-btn{
	margin: 10px;
	color: white;
	padding: 10px;
	border-radius: 50%;
	background-color: rgb(15, 63, 239);
	border: none;
}

.caller{
	padding: 1em;
	display: flex;
	display: block;
}

.call-noti-btn{
	margin: 10px;
	color: white;
	padding: 10px;
	border-radius: 50%;
	border: none;
	float: right;
}

.call-noti-btn:hover{
	opacity: 0.5;
}