.mainDiv {
  max-height: 100vh;
  overflow: auto;
}
.mainDiv::-webkit-scrollbar {
  display: none;
}

.dark-mode-inner {
  background-color: #1a1919;
  transition: background-color 0.25s ease-in-out;
}
.light-mode-inner {
  background-color: white;
  transition: background-color 0.25s ease-in-out;
}
