.infoInput{
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 100%;
    font-size: small;
    border: none;
    border-radius: 7px;
    background-color: rgb(220, 220, 220);
}
.infoForm{
    font-size: small;
    justify-items: center;
}
.logout-button{
    width: 100%;
    height: 2rem;
    margin-top: 2rem;
    align-self: flex-start;
}

.dark-mode-inner{
    background-color: #1a1919;
    transition: background-color 0.25s ease-in-out;
}
.light-mode-inner{
    background-color: white;
    transition: background-color 0.25s ease-in-out;
}