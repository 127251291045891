.FollowersCard{
    padding-left: 24px;
    width: 93%;
    border-radius: 0.7rem;
    /* gap: 1rem; */
    display: flex;
    flex-direction: column;
    font-size: 13px;
    max-height: 10rem;
}
.follower{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.follower>div{
    display: flex;
    gap: 10px;
}

.dark-mode-inner{
    background-color: #1a1919;
    transition: background-color 0.25s ease-in-out;
  }
  .light-mode-inner{
    background-color: white;
    transition: background-color 0.25s ease-in-out;
  }

.followerImage{
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
}

.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.name>span:nth-of-type(1){
    font-weight: bold;
}



.fc-button{
    height: 2rem;
    width: 35%;
    padding-left: 5px;
    padding-right: 5px;
}

.UnfollowButton{
    color: var(--orange);
    border: 2px solid var(--orange);
    cursor: pointer;
    background: transparent;
}


.FollowersCard>span{
    font-weight: bold;
    color: orange;
    align-self: center;
    cursor: pointer;
}