.InfoCard
{
    
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;
}

.dark-mode-inner{
    background-color: #1a1919;
    transition: background-color 0.25s ease-in-out;
  }
  .light-mode-inner{
    background-color: white;
    transition: background-color 0.25s ease-in-out;
  }

.infoHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoHead>div:hover{
    cursor: pointer;
}
.info{
    /* margin-left: 1em; */
}
.logout-button{
    width: 100%;
    height: 2rem;
    margin-top: 2rem;
    align-self: flex-start;
}