.ProfileCard {
    border-radius: 10px;
    margin: 0.5em;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  
  .dark-mode-inner{
    background-color: #1a1919;
    transition: background-color 0.25s ease-in-out;
  }
  .light-mode-inner{
    background-color: white;
    transition: background-color 0.25s ease-in-out;
  }

  /* Profile Images */
  .ProfileImages {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .ProfileImages > img:nth-of-type(1) {
    width: 111%;
  }
  .ProfileImages > img:nth-of-type(2) {
    width: 5rem;
    height: 5em;
    border-radius: 50%;
    position: absolute;
    bottom: -3rem;
    box-shadow: var(--profileShadow);
  }
  
  /* Profile Name */
  .ProfileName {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    gap: 10px;
  }
  
  .ProfileName > span:nth-of-type(1) {
    font-weight: bold;
  }
  
  /* Follow Status */
  .followStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .hr{
    margin: 0.5em;
    width: 90%;
    height: 1px;
    background-color: rgb(83, 81, 81);
  }
  
  .followStatus > div {
    display: flex;
    width: 80%;
    justify-content: space-around;
    align-items: center;
  }
  
  .follow {
    display: flex;
    flex-direction: column;
    /* gap: 0.4rem; */
    align-items: center;
    justify-content: center;
  }
  .follow > span:nth-of-type(1) {
    font-weight: bold;
  }
  
  .follow > span:nth-of-type(2) {
    color: var(--gray);
    font-size: 13px;
  }
  
  .vl {
    height: 100px;
    width: 0.5px;
    background-color: rgb(83, 81, 81);
  }
  
  
  
  
  .ProfileCard>span
  {
      font-weight: bold;
      color: orange;
      align-self: center;
      margin-bottom: 1rem;
      cursor: pointer;
  }