.logoImg{
    width: 49px;
    height: 50px;
}
.LogoSearch {
    display: flex;
    margin-top: 1em;
    border-radius: 0.7em;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .dark-mode-inner{
    background-color: #1a1919;
    transition: background-color 0.25s ease-in-out;
  }
  .light-mode-inner{
    background-color: white;
    transition: background-color 0.25s ease-in-out;
  }
  
  .Search {
    display: flex;
    background-color: var(--inputColor);
    border-radius: 10px;
    padding: 1px;
  }
  .Search>input {
    border-radius: 10px;
    background-color: rgb(220, 220, 220);
    border: none;
    outline: none;
    height: 2em;
    padding-left: 7px;
    position: relative;
  }
  .s-icon {
    position: absolute;
    margin-left: 11em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(106.23deg, #f99827 0%, #f95f35 100%);
    border-radius: 5px;
    padding: 4px;
    color: white;
  }

  .dataResult {
    position: absolute;
    margin-top: 2.2em;
    margin-left: 31px;
    width: 242px;
    height: min-content;
    background-color: white;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
    padding: 10px;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }