.share{
    border-radius: 20px;
    height: 80px;
    /* background-color:white; */
}

.dark-mode-inner{
    background-color: #1a1919;
    transition: background-color 0.25s ease-in-out;
  }
  .light-mode-inner{
    background-color: white;
    transition: background-color 0.25s ease-in-out;
  }
.profile-pic{
    width: 40px;
    height: 40px;
    background-color: white;
    margin-top: 0.7em;
}
.share-desc{
    margin-left: 20px;
    margin: 0.5em;
    width: 96%;
    margin-left: 20px;
}
.text-with-icon{
    float: left;
}
.button{
    border-radius: 10px;
    border-style: none;
    border-color: azure;
    background-color: rgb(239, 131, 8);
    color: white;
    font-size: small;
}

.PostShare{
    display: flex;
    /* gap: 1rem; */
    /* background-color: white; */
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 0.7em;
}


.PostShare>img{ 
    margin-right: 10px;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
}
.PostShare>div{
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 1rem;
}

.PostShare>div>input{
    border-radius: 10px;
    background-color: #DCDCDC;
    border-radius: 10px;
    padding: 10px;
    font-size: 17px;
    border: none;
    outline: none;
}

.postOptions{
    display: flex;
    justify-content: space-around;
}

.option{
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.option:hover{
    cursor: pointer;
}
.ps-button{
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
}


.previewImage{
    position: relative;
    /* display: flex; */
}

.previewImage>svg{
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}

.previewImage>img{
    width: 100%;
    max-height: 20rem;
    object-fit: cover;
    border-radius: 0.5rem;
}
.previewVideo>video{
    width: 100%;
    height: auto;
}